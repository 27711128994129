import React, { useState, useEffect } from 'react';
import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";
import { query, where, getDocs } from "firebase/firestore";
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, ReferenceDot, Label } from 'recharts';
import logo from './logo.png';
import './App.css';


// Your web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBHe0r7acyx9CXZRGQNVkyh3xv_NU8EH1E",
    authDomain: "govinedsurvey.firebaseapp.com",
    projectId: "govinedsurvey",
    storageBucket: "govinedsurvey.appspot.com",
    messagingSenderId: "340098086825",
    appId: "1:340098086825:web:5a332368fe9831406cb6b8"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);


function DemographicForm() {
    const [region, setRegion] = useState('');
    const [role, setRole] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [submissionError, setSubmissionError] = useState('');
    const [comparisonMode, setComparisonMode] = useState('sameRoleRegion'); // default mode
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [showCharts, setShowCharts] = useState(false);


    const [values, setValues] = useState({
        regulations: 3,
        coordination: 3,
        collaboration: 3,
        decisionMaking: 3,
        compartmentalisation: 3,
        feedbackLoops: 3,
        vision: 3,
    });

    const dimensionDescriptions = {
        regulations: "Regulations e.g. requirements, laws, processes (6-stage scale: weak 1 - strong 6)",
        coordination: "Coordination e.g. organization, communication (6-level scale: weak 1 - strong 6)",
        collaboration: "Collaboration (6-point scale: weak 1 - strong 6)",
        decisionMaking: "Decision-making processes (6-stage scale: weak involvement of all 1 - strong involvement of all 6)",
        compartmentalisation: "Compartmentalization (permeability and connectivity or demarcation) (6-point scale: high degree of demarcation 1 – high degree of permeability and connectivity 6)",
        feedbackLoops: "Feedback loops (6-level scale: not established 1 - coherent and effective feedback loops exist 6)",
        vision: "Vision (6-point scale: weak 1 - strong 6)"
    };

    const chartDescription = {
        regulations: "Regulations e.g. requirements, laws, processes",
        coordination: "Coordination e.g. organization, communication",
        collaboration: "Collaboration",
        decisionMaking: "Decision-making processes",
        compartmentalisation: "Compartmentalization (permeability and connectivity or demarcation)",
        feedbackLoops: "Feedback loops",
        vision: "Vision"
    };

    const dimensionAxisDescriptions = {
        regulations: "weak 1 - strong 6",
        coordination: "weak 1 - strong 6",
        collaboration: "weak 1 - strong 6",
        decisionMaking: "weak involvement of all 1 - strong involvement of all 6",
        compartmentalisation: "high demarcation 1 - high permeability and connectivity 6",
        feedbackLoops: "not established 1 - coherent and effective feedback loops exist 6",
        vision: "weak 1 - strong 6"
    };

    const roleDescriptions = {
        parliament_federal: "Parliament or federal politics",
        ministry_education: "Ministry of Education",
        state_government: "State government or state politics",
        education_directorate: "Education directorate or school authority at regional level",
        regional_politics: "Regional politics or district politics or city politics",
        education_inspection: "Education inspection or school quality management",
        diversity_management: "Diversity management and diversity centers/advice centers at regional level",
        school_psychological: "School psychological services",
        allopathic_medical: "Allopathic medical services",
        pedagogical_services: "Pedagogical services (e.g., psycho-educational advisory teams)",
        school_administration: "School administration & school maintainers (city, municipality)",
        headteachers: "Headteachers",
        teachers: "Teachers",
        pedagogical_specialists: "Pedagogical specialists working on site in schools (school assistance, nursing assistance, communication assistance)",
        multi_professional: "Other specialists in multi-professional cooperation (speech therapists, occupational therapists, physiotherapists, autism trainers, etc.)",
        pupils: "Pupils",
        students: "Students",
        mothers: "Mothers",
        fathers: "Fathers",
        teachers_career_start: "Teachers at the start of their careers (also with part-time studies, but already working)",
        teacher_training: "Teacher training (colleges, universities)",
        teacher_education: "Teacher training and further education",
        parents_associations: "Parents' associations",
        student_organizations: "Student organizations",
        student_associations: "Student associations",
        self_representatives: "Self-representatives (inclusion activists, members of a monitoring committee or a publicly established working group for inclusion, etc.)",
        clubs_associations: "Clubs and associations related to integrative or inclusive education",
        charities: "Charities (NGOs if they are involved in managing resources or providing financial support to schools)",
        others: "Others"
    };



    const handleSliderChange = (name) => (event) => {
        setValues({ ...values, [name]: event.target.value });
    };

    const handleComparisonModeChange = (mode) => () => {
        setComparisonMode(mode);
    };


    const ResultsChart = ({ db, region, role, userValues, comparisonMode }) => {
        const [data, setData] = useState({});
        const [loading, setLoading] = useState(true);

        useEffect(() => {
            const fetchData = async () => {
                setLoading(true);
                let conditions = [];
                if (comparisonMode === 'sameRoleRegion') {
                    conditions.push(where("role", "==", role), where("region", "==", region));
                } else if (comparisonMode === 'sameRegion') {
                    conditions.push(where("region", "==", region));
                } else if (comparisonMode === 'sameRole') {
                    conditions.push(where("role", "==", role));
                }

                const q = query(collection(db, "responses"), ...conditions);
                const querySnapshot = await getDocs(q);
                const allResponses = {};

                querySnapshot.forEach((doc) => {
                    const responseValues = doc.data().values;
                    Object.keys(responseValues).forEach(key => {
                        if (!allResponses[key]) {
                            allResponses[key] = Array(6).fill(0);
                        }
                        allResponses[key][responseValues[key] - 1] += 1;
                    });
                });

                setData(allResponses);
                setLoading(false);
            };

            fetchData();
        }, [db, region, role, comparisonMode]);

        if (loading) {
            return <p>Loading data...</p>;
        }

        // Define the order of the keys
        const keyOrder = ['regulations', 'coordination', 'collaboration', 'decisionMaking', 'compartmentalisation', 'feedbackLoops', 'vision'];

        return (
            <div>
                {keyOrder.map((key) => {
                    if (data[key]) {  // Check if the data for the key exists
                        const values = data[key];
                        return (
                            <div key={key}>
                                <h3>{chartDescription[key]}</h3>
                                <div className="chart-wrapper">
                                    <AreaChart width={580} height={250} data={values.map((count, index) => ({ name: index + 1, count }))}
                                        margin={{ top: 10, right: 30, left: 0, bottom: 30 }}>
                                        <defs>
                                            <linearGradient id={`${key}-color`} x1="0" y1="0" x2="0" y2="1">
                                                <stop offset="5%" stopColor="#f3f4ef" stopOpacity={0.8} />
                                                <stop offset="95%" stopColor="#f3f4ef" stopOpacity={0} />
                                            </linearGradient>
                                        </defs>
                                        <XAxis dataKey="name">
                                            <Label value={dimensionAxisDescriptions[key]} offset={-10} position="insideBottom" />
                                        </XAxis>
                                        <YAxis />
                                        <CartesianGrid strokeDasharray="3 3" />
                                        <Tooltip />
                                        <Area type="monotone" dataKey="count" stroke="#da9c27" fillOpacity={1} fill={`url(#${key}-color)`} />
                                        <ReferenceDot x={Number(userValues[key])} y={0} r={8} fill="#da9c27" stroke="none" label="" />
                                    </AreaChart>
                                </div>
                            </div>
                        );
                    }
                    return null; // If there's no data for a key, return null (no chart for that key)
                })}
            </div>
        );
    };


    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!region || !role) {
            setSubmissionError('Please select both region and function before submitting.');
            return;
        }
        setIsSubmitting(true);
        setSubmissionError('');
        try {
            const docRef = await addDoc(collection(db, "responses"), {
                region: region,
                role: role,
                values: values
            });
            console.log("Document written with ID: ", docRef.id);
            setShowCharts(true); // Automatically show charts on successful submission
            setFormSubmitted(true);
            setIsSubmitting(false);
        } catch (e) {
            console.error("Error adding document: ", e);
            setSubmissionError('Failed to submit data. Please try again.');
            setIsSubmitting(false);
        }
    };

    const getComparisonModeText = () => {
        let roleDescription = roleDescriptions[role] || role; // Default to role if not found in descriptions
        switch (comparisonMode) {
            case 'sameRoleRegion':
                return `Comparing results in ${region} for the role of ${roleDescription}`;
            case 'sameRegion':
                return `Comparing all roles within ${region}`;
            case 'sameRole':
                return `Comparing the role of ${roleDescription} across all regions`;
            case 'allRegionsRoles':
                return "Comparing all roles across all regions";
            default:
                return "Comparison Mode";
        }
    };



    return (
        <div className="survey-container">
            <h1>Survey and comparison</h1>
            {!formSubmitted ? (
                <>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Your Region:
                            <select value={region} onChange={e => setRegion(e.target.value)}>
                                <option value="">Select Region</option>
                                <option value="Baden-Württemberg">Baden-Württemberg</option>
                                <option value="Catalonia">Catalonia</option>
                                <option value="Styria">Styria</option>
                                <option value="South-Tyrol">South Tyrol</option>
                                <option value="other">Other</option>
                            </select>
                        </label>

                        <label>
                            Your Function:
                            <select value={role} onChange={e => setRole(e.target.value)}>
                                <option value="">Select Function</option>
                                <option value="parliament_federal">Parliament or federal politics</option>
                                <option value="ministry_education">Ministry of Education</option>
                                <option value="state_government">State government or state politics</option>
                                <option value="education_directorate">Education directorate or school authority at regional level</option>
                                <option value="regional_politics">Regional politics or district politics or city politics</option>
                                <option value="education_inspection">Education inspection or school quality management</option>
                                <option value="diversity_management">Diversity management and diversity centers/advice centers at regional level</option>
                                <option value="school_psychological">School psychological services</option>
                                <option value="allopathic_medical">Allopathic medical services</option>
                                <option value="pedagogical_services">Pedagogical services (e.g. psycho-educational advisory teams)</option>
                                <option value="school_administration">School administration & school maintainers (city, municipality)</option>
                                <option value="headteachers">Headteachers</option>
                                <option value="teachers">Teachers</option>
                                <option value="pedagogical_specialists">Pedagogical specialists working on site in schools (school assistance, nursing assistance, communication assistance)</option>
                                <option value="multi_professional">other specialists in multi-professional cooperation (speech therapists, occupational therapists, physiotherapists, autism trainers, etc.)</option>
                                <option value="pupils">Pupils</option>
                                <option value="students">Students</option>
                                <option value="mothers">Mothers</option>
                                <option value="fathers">Fathers</option>
                                <option value="teachers_career_start">Teachers at the start of their careers (also with part-time studies, but already working)</option>
                                <option value="teacher_training">Teacher training (colleges, universities)</option>
                                <option value="teacher_education">Teacher training and further education</option>
                                <option value="parents_associations">Parents' associations</option>
                                <option value="student_organizations">Student organizations</option>
                                <option value="student_associations">Student associations</option>
                                <option value="self_representatives">Self-representatives (inclusion activists, members of a monitoring committee or a publicly established working group for inclusion, etc.)</option>
                                <option value="clubs_associations">Clubs and associations related to integrative or inclusive education</option>
                                <option value="charities">Charities (NGOs if they are involved in managing resources or providing financial support to schools)</option>
                                <option value="others">others</option>
                            </select>
                        </label>
                        <br />
                        <p>How do you personally assess the development in the individual dimensions with regard to the management of inclusive education in your region?</p>
                        {Object.entries(values).map(([key, value]) => (
                            <div key={key} className="slider-container">
                                <br />
                                <label >
                                    {dimensionDescriptions[key]}
                                </label>

                                <label style={{ fontWeight: 400 }} className="slider-value-label">{value}</label>
                                <input type="range" min="1" max="6" value={value} onChange={handleSliderChange(key)} />
                            </div>
                        ))}
                        {isSubmitting && <p>Submitting data...</p>}
                        {submissionError && <p className="error">{submissionError}</p>}
                        <button type="submit" disabled={isSubmitting}>Submit</button>
                    </form>
                </>
            ) : (
                <>
                    <h2>{getComparisonModeText()}</h2>
                    <br />
                    <div className='comparison-options'>
                        <p>Comparison Options:</p>
                        <button className="comparison-button" onClick={handleComparisonModeChange('sameRoleRegion')}>Same Region & Role</button>
                        <button className="comparison-button" onClick={handleComparisonModeChange('sameRegion')}>All Roles in Region</button>
                        <br />
                        <button className="comparison-button" onClick={handleComparisonModeChange('sameRole')}>All Regions for Role</button>
                        <button className="comparison-button" onClick={handleComparisonModeChange('allRegionsRoles')}>All Regions & Roles</button>
                    </div>

                    <br /><br />
                    {showCharts && (
                        <ResultsChart db={db} region={region} role={role} userValues={values} comparisonMode={comparisonMode} />
                    )}
                </>
            )}

        </div>
    );


}




function App() {
    return (
        <div className="App">
            <header className="App-header">
                <img src={logo} alt="Logo" />
                <DemographicForm />
            </header>
        </div>
    );
}


export default App;
